(function ($) {
  Drupal.behaviors.countryChooserV1 = {
    attach: function (context) {
      var $template = $('.js-country-chooser--v1', context);
      var $countrySelect = $('select.country-select__selectbox', $template);

      $countrySelect.on('change', function () {
        var loc = $(this).val();

        if (loc) {
          window.location = loc;
        }
      });
    }
  };
})(jQuery);
